import React from 'react';
import Modal from 'react-modal';
import { useSelector} from "react-redux";
import Invoice from "./invoice/Invoice";


const OrderModal= ({ isOpen, onRequestClose }) => {
    const orderDetailData = useSelector((state) =>  state.inventory.orderDetailData);

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='description-modal' ariaHideApp={false}>
            <div className="container">
            </div>
            <Invoice/>
        </Modal>
    );
};


export default OrderModal;
