// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import TextArea from "../../Layout/Form/TextArea";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import {makeApiCall} from "../../../hooks/api/makeApiCall";
import {SCHOOL_LIST} from "../../../store/constant/salesConstant";

const GemSchoolForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const validationRules = {
        bid_number: { required: true, minLength: 3, maxLength: 50,field_name: ' Bid Number' },
        bid_copy: { required: true,field_name: ' Bid Copy' },
        opening_date: { required: true, field_name: ' Opening Date'},
    };
    const onSuccess = (res)=>{
       /* makeApiCall(`sales/get-school-list-dropdown`,SCHOOL_LIST,dispatch);
        handleExternalFetch?.(res,['school']);*/
        onRequestClose();
    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged,updatePinCode } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container max-h-[80vh] overflow-y-auto rounded-lg w-[90%]">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Bid Details' : 'Add Gem Bid Details'}/>
                <form onSubmit={(e) => handleSubmit(e,validationRules,'gem_bid')}>
                    <div className='vendor-details'>
                        <input type={'hidden'} name={'client_lead_id'} value={selectedData.client_lead_id}/>
                    <InputField
                        type="text"
                        name="bid_number"
                        value={formData.bid_number || ''}
                        onChange={handleChange}
                        error={errors.bid_number} placeholder={'Bid Number'} label={'Bid Number'}
                    />
                    <InputField
                        type="file"
                        name="bid_copy"
                        value={formData.bid_copy || ''}
                        onChange={handleChange}
                        error={errors.bid_copy} label={'Bid Copy'}
                    />
                    <InputField
                        type="date"
                        name="opening_date"
                        value={formData.opening_date || ''}
                        onChange={handleChange}
                        error={errors.opening_date} placeholder={'Bid Opening Date'} label={'Bid Opening Date'}
                    />
                        <TextArea
                            className='text-area'
                            name="remark"
                            placeholder="Remark"
                            onChange={handleChange} label={'Remark if Any'}
                        />
                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default GemSchoolForm;
