// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import TextArea from "../../Layout/Form/TextArea";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import SelectCityDropdown from "../../Sales/region/SelectCityDropdown";
import SelectStateDropdown from "../../Sales/region/SelectStateDropdown";
import {PinCodeText} from "../../Sales/region/PinCodeText";

const PackagingForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    //const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.inventory.inventoryEventTypeId);

    const validationRules = {
        name: { required: true, minLength: 3, maxLength: 150 },
        amount: { required: true },
    };
    const onSuccess = (res)=>{
        onRequestClose();
        handleExternalFetch?.(res,['packaging']);

    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Packaging Item' : 'Add Packaging Item'} />
                <form onSubmit={(e) => handleSubmit(e,validationRules,'packaging_items',{created_by:auth?.id},[])}>
                    <div className='vendor-details'>
                        <InputField
                            label=" Packaging Items Name"
                            type="text"
                            name="name"
                            value={formData.name || ''}
                            onChange={handleChange}
                            error={errors.name} placeholder='Enter Packaging Items Name'
                        />
                        <InputField
                            label="Amount"
                            type="number"
                            name="amount"
                            value={formData.amount || ''}
                            onChange={handleChange}
                            error={errors.amount}
                            placeholder={'Amount'}
                            min="0" // Set minimum value to avoid negative amounts
                            step="0.01" // Allows decimal input for amounts with cents
                        />
                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default PackagingForm;
