const API_URL =
    process.env.REACT_APP_DEVELOPMENT_MODE === 'development'
        ? "http://localhost:4024/api/"
        : "https://stemcity.in/api/";


const Apis = {
  //Authentication api
  GetUserLogin: `${API_URL}/api/customer/login`,
  GetUserRegister: `${API_URL}/api/customer/register`,
  GetCustomerDetails: `${API_URL}/api/customer/getUserByEmailId?email=`,


};
const subDomainUrl = '';
export { API_URL, Apis,subDomainUrl };
