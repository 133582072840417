import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowUp, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import EditSubCategory from "./EditSubCategory";
import PreLoader from "../../loader/PreLoader";
import swal from "sweetalert";
import axios from 'axios';
import {API_URL} from "../../../hooks/config";
import {actionToGetSubCategoriesApiCall} from "../../../store/action/inventoryActions";
import {callSoftDeleteDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import CompanyPipeline from "../../WebSetting/website_settings/company/CompanyPipeline";
export const SubCategoryTable = () => {
    const [isEditSubCategoryOpen, setIsEditSubCategoryOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const subCategoryData = useSelector((state) =>  state.inventory?.subCategoryData);
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const fileInputRef=useRef();
    const [source,setSource]=useState(auth?.source);
    const [subCategoryListData, setSubCategoryListData] = useState([]);

    const changeCompany = (val)=>{
        setSource(val.id);
    }

    const subCategoryFilterFunction = () =>{
        let subCategoryList=[]
        subCategoryData !== 'loading' && subCategoryData?.map(subCategoryArray=>{
            if(subCategoryArray.source ==source){
                subCategoryList.push(subCategoryArray);
            }
        })
        setSubCategoryListData(subCategoryList);
    }
    useEffect(() => {
        if(source){
            subCategoryFilterFunction();
        }
    }, [source,subCategoryData]);

    useEffectOnce(()=>{
        dispatch(actionToGetSubCategoriesApiCall());
    })

    const deleteSubCategory = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Sub-Category "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callSoftDeleteDataWithLogFunction({tableName:'sub_categories',id:row.id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetSubCategoriesApiCall())
                    })
                }
            });

    };

   /* const [selectedFile, setSelectedFile] = useState(null);*/

    const handleFileChange = (event,id) => {
        const file = event.target.files[0];
        const fileType = file.type;

        const validTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'text/csv'
        ];

        if (validTypes.includes(fileType)) {
            //setSelectedFile(file);
            handleUpload(file,id)
        } else {
            alert('Please select a valid Excel or CSV file.');
        }
    };

    const handleUpload = async (file,id) => {
        if (!file) {
            alert('Please select a file first.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', id);
        try {
            //dispatch(actionToImportExcelOfProductsApiCall(formData));
            const response = await axios.post(API_URL+'inventory/actionToImportExcelOfProductsApiCall', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('File uploaded successfully!');
        } catch (error) {
            console.error('There was an error uploading the file!', error);
            alert('Failed to upload file.');
        }
    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(subCategoryListData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === subCategoryListData.length);
    };
    return(
        <>
            {
                subCategoryData === 'loading' ?
                    <PreLoader/>
                    :

                    <>
                        <CompanyPipeline source={source} changeCompany={changeCompany}/>
                        <div className="table">
                            <div className="row-header">
                                <div className="four-column-cell">
                                    <input className='checkbox' type="checkbox"
                                           checked={selectAll}
                                           onChange={handleSelectAll}/>
                                    Sub-Category
                                </div>
                                <div className="four-column-cell">Main Category</div>
                                <div className="four-column-cell">Company</div>
                                <div className="four-column-cell">Action</div>
                            </div>
                            <Virtuoso style={{ height: '600px' }} totalCount={subCategoryListData.length} itemContent={index =>
                                <div className="table-row">
                                    <div className="four-column-cell">
                                        <input className='checkbox' type="checkbox"
                                               checked={selectedRows.includes(subCategoryListData[index]?.id)}
                                               onChange={() => handleSelectRow(subCategoryListData[index]?.id)}/>
                                        {subCategoryListData[index]?.name}
                                    </div>
                                    <div className="four-column-cell">{subCategoryListData[index]?.category}</div>
                                    <div className="four-column-cell">{subCategoryListData[index]?.company_name}</div>
                                    <div className="four-column-cell">
                                        <div className='action-components'>
                                            <div className='action-buttons'>
                                                <Tippy content='Upload Product'>
                                                <button className='action-button' onClick={()=>fileInputRef.current.click()}>
                                                    <FontAwesomeIcon action-icon icon={faCloudArrowUp} />
                                                </button></Tippy>
                                                <input type="file" accept=".xlsx, .xls, .csv" multiple={false} ref={fileInputRef} onChange={(e)=>handleFileChange(e,subCategoryListData[index]?.id)} hidden/>
                                            </div>
                                            <div className='action-buttons'>
                                                <Tippy content='Edit Sub-Category'>
                                                <button className='action-button' onClick={() => {setIsEditSubCategoryOpen(true); setSelectedIndex(index);}} >
                                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                                </button></Tippy>
                                            </div>
                                            <div className='action-buttons'>
                                                <Tippy content='Delete Sub-Category'>
                                                <button onClick={()=>deleteSubCategory(subCategoryListData[index])} className='action-button'>
                                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                                </button></Tippy>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            } />
                        </div>
                        </>
            }
            <EditSubCategory isOpen={isEditSubCategoryOpen} selectedData={subCategoryListData[selectedIndex]} onRequestClose={() => {setIsEditSubCategoryOpen(false); setSelectedIndex(false);}} />
        </>
    )
}
