// hooks/useFetchData.js
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {makeApiCall} from "./api/makeApiCall";

export const useFetchData = (apiUrl, initialData = {}, advanceFilter = {}) => {
    const [data, setData] = useState(initialData);
    const [settings, setSettings] = useState({
        search: "",
        sortField: "id",
        sortDirection: "desc",
        page: 1,
        pageSize: 10,
    });
    const dispatch = useDispatch();

    const fetchData = (payload) => {
        const params = { ...settings, ...payload, advanceFilter };
        makeApiCall(apiUrl,null,null,params).then((response) => setData(response));
    };

    return { data, settings, setSettings, fetchData };
};
