import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGlobe, faHandshake, faHandshakeSlash, faPenToSquare,
    faSchool,
    faUserPlus

} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {Link, useParams} from "react-router-dom";
import {subDomainUrl} from "../../hooks/config";
import SchoolTable from "./school/SchoolTable";
import {RegionTable} from "./region/RegionTable";
import AddRegion from "./region/AddRegion";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {useDispatch, useSelector} from "react-redux";
import {
    actionToGetCityListApiCall, actionToGetFormAuthRuleApiCall,
    actionToGetPinCodeListApiCall,
    actionToGetStateListApiCall
} from "../../store/action";
import SchoolForm from "./school/SchoolForm";
import TodaySalesForm from "./work-report/TodaySalesForm";
import SalesReportTable from "./work-report/SalesReportTable";
import {makeApiCall} from "../../hooks/api/makeApiCall";
import {
    BUSINESS_TYPE,
    FOLLOW_UP_TYPE_LIST,
    ORDER_TYPE_LIST, SALES_DASHBOARD_CARD_COUNT,
    SCHOOL_LIST,
    SCHOOL_TYPE_LIST
} from "../../store/constant/salesConstant";
import {DESIGNATION_LIST} from "../../store/constant";
import SchoolClientLeadTable from "./client-lead/SchoolClientLeadTable";
import SchoolClientLeadForm from "./client-lead/SchoolClientLeadForm";
import SchoolContactPersonForm from "./contact-person/SchoolContactPersonForm";
import SchoolContactPersonTable from "./contact-person/SchoolContactPersonTable";
import AuthorizedComponent from "../auth/AuthorizedComponent";
import {actionToGetUsersListApiCall} from "../../store/action/usersActions";
import {actionToGetCompanyListApiCall} from "../../store/action/commonActions";


const SalesComponent = React.memo(function () {
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);
    const formAuthRule = useSelector((state) => state.common.formAuthRule);
    let {tabName} = useParams();
    const dispatch = useDispatch();
    const [addModalName, setAddModalName] = useState(false);
    const [fetchDataTable, setFetchDataTable] = useState(false);
    const [activeTab, setActiveTab] = useState('lead-interaction');

    const handleExternalFetch = (res,affetcedTab) => {
        if (affetcedTab?.includes(activeTab)) {
            setFetchDataTable(true);  // Example call to fetchData with parameters
        }
    };
    const getTabTable =() =>{
        switch (activeTab){
            case 'client-lead':
                return <SchoolClientLeadTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            case 'contact-person':
                return <SchoolContactPersonTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            case 'region':
                return <RegionTable />;
            case 'school':
                return <SchoolTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            case 'lead-interaction':
                return <SalesReportTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            default:
                return <SchoolTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
        }
    }
    useEffect(() => {
        setActiveTab(tabName);
    }, [tabName]);
    useEffectOnce(()=>{
        dispatch(actionToGetFormAuthRuleApiCall({eventTypeId:eventTypeId}));
        dispatch(actionToGetStateListApiCall());
        dispatch(actionToGetCityListApiCall());
        dispatch(actionToGetPinCodeListApiCall());
        dispatch(actionToGetUsersListApiCall({not_customer:true}));
        dispatch(actionToGetCompanyListApiCall({in_inventory_use:1}));
        makeApiCall(`sales/get-school-list-dropdown`,SCHOOL_LIST,dispatch);
        makeApiCall(`sales/get-school-type`,SCHOOL_TYPE_LIST,dispatch);
        makeApiCall(`sales/get-order-type`,ORDER_TYPE_LIST,dispatch);
        makeApiCall(`sales/get-follow-up-type`,FOLLOW_UP_TYPE_LIST,dispatch);
        makeApiCall(`common/get-designation-list`,DESIGNATION_LIST,dispatch);
        makeApiCall(`sales/get-business-type`,BUSINESS_TYPE,dispatch);

    })
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                                <Link to={subDomainUrl+'/sales/lead-interaction'} className='tab'><button
                                    className={`tab ${activeTab === 'lead-interaction' ? 'active' : ''}`}>
                                   Lead Interaction
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/sales/school'} className='tab'><button
                                    className={`tab ${activeTab === 'school' ? 'active' : ''}`}>
                                   Schools
                                </button>
                                </Link>
                                <AuthorizedComponent allowedRoles={formAuthRule && formAuthRule['region_module']?.split(",")}
                                                        AuthComponent={<>
                                                            <Link to={subDomainUrl+'/sales/region'} className='tab'><button
                                                                className={`tab ${activeTab === 'region' ? 'active' : ''}`}>
                                                                Region
                                                            </button>
                                                            </Link> </>}
                                                        Component={''}/>

                                <Link to={subDomainUrl+'/sales/client-lead'} className='tab'><button
                                    className={`tab ${activeTab === 'client-lead' ? 'active' : ''}`}>
                                    Business Regarding
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/sales/contact-person'} className='tab'><button
                                    className={`tab ${activeTab === 'contact-person' ? 'active' : ''}`}>
                                   Contact Person
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <Tippy content='Add School'>
                                <button  className='common-button' onClick={() => setAddModalName('add_school')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faSchool} />
                                </button>
                            </Tippy>
                            <AuthorizedComponent allowedRoles={formAuthRule && formAuthRule['region_module']?.split(",")}
                                                 AuthComponent={<>
                                                     <Tippy content='Add Region'>
                                                         <button  className='common-button' onClick={() => setAddModalName('add_region')}>
                                                             <FontAwesomeIcon className='filter-icon' icon={faGlobe} />
                                                         </button>
                                                     </Tippy> </>}
                                                 Component={''}/>

                            <Tippy content='Add Contact Person'>
                                <button  className='common-button' onClick={() => setAddModalName('add_contact_person')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faUserPlus} />
                                </button>
                            </Tippy>

                            <Tippy content='Add Business Regarding'>
                                <button  className='common-button' onClick={() => setAddModalName('add_client_lead')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faHandshake} />
                                </button>
                            </Tippy>
                            <Tippy content='Add Today Sales Report'>
                                <button  className='common-button' onClick={() => setAddModalName('add_today_sales_report')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faHandshakeSlash} />
                                </button>
                            </Tippy>
                        </div>

                    </div>
                </div>
                <div className="tab-content">
                    {getTabTable()}
                </div>
                <AddRegion isOpen={addModalName === 'add_region'} onRequestClose={() => setAddModalName(false)} />
                <SchoolForm
                    handleExternalFetch={handleExternalFetch}
                    isOpen={addModalName === 'add_school'}
                    onRequestClose={() => setAddModalName(false)}
                    isEditMode={false}
                    selectedData={{}}
                />
                <TodaySalesForm
                    handleExternalFetch={handleExternalFetch}
                    isOpen={addModalName === 'add_today_sales_report'}
                    onRequestClose={() => setAddModalName(false)}
                    isEditMode={false}
                    selectedData={{}}
                />
                {addModalName === 'add_client_lead' && (
                    <SchoolClientLeadForm
                        isOpen={addModalName=== 'add_client_lead'}
                        onRequestClose={() => setAddModalName(false)}
                        isEditMode={false}
                    />
                )}
                {addModalName === 'add_contact_person' && (
                    <SchoolContactPersonForm
                        isOpen={addModalName=== 'add_contact_person'}
                        onRequestClose={() => setAddModalName(false)}
                        isEditMode={false}
                    />
                )}
            </section>
        </>
    )
});

export default SalesComponent;
