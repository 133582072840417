// components/TransporterTable.js
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchData } from '../../../hooks/useFetchData';
import { callSoftDeleteDataWithLogFunction } from '../../../store/action/commonActions';
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import useAuth from "../../../hooks/useAuth";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import Tippy from "@tippyjs/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox} from "@fortawesome/free-solid-svg-icons";
import TransporterForm from "./TransporterForm";

const TransporterTable = ({setFetchDataTable,fetchDataTable,selectedSchool}) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.inventory.inventoryEventTypeId);

    const { data:transporter, fetchData,settings } = useFetchData(`inventory/get-transporter`, []);
    const [isEditTransporterOpen, setIsEditTransporterOpen] = useState(false);
    const [selectedTransporter, setSelectedTransporter] = useState(null);
    const [columns, setColumns] = useState([]);

    // Define default columns
    const defaultColumns = [
        { name: 'Sr No', selector: row => row.id, selector_field:'id',
            cell: (row,index) => (
                <>
                    {index+1}
                </>
            ),width:'60px'  },
        { name: 'Name', selector: row => row.name, selector_field:'name',
            cell: row => (
                <>
                    {row.name}
                </>
            ), sortable: true ,width:'150px' },
        { name: 'email', selector: row => row.email, selector_field:'email',sortable: true,width:'250px'  },
        { name: 'phone', selector: row => row.phone, selector_field:'phone',sortable: true,width:'200px'  },
        { name: 'Creator', selector: row => row.creator, selector_field:'creator',sortable: true,width:'200px' },
        { name: 'Actions', cell: (row) => (
                <div className='action-components'>
                    <TableEditActions
                        tooltip={"Edit"}
                        row={row}
                        onEdit={() => { setSelectedTransporter(row); setIsEditTransporterOpen('edit'); }}
                    />
                    <TableDeleteActions
                        tooltip={"Delete"}
                        row={row}
                        onDelete={(row) => dispatch(callSoftDeleteDataWithLogFunction({ tableName: 'transporter', id: row.id, userId: auth?.id, eventTypeId })).then(fetchData)}
                    />
                </div>
            ), width: '150px' }
    ];

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable]);
    // Update columns based on conditions
    useEffect(() => {
        let newColumns = [...defaultColumns];
        setColumns(newColumns);
    }, [auth]);
    return (
        <>
            <DataTableLayout columns={columns} data={transporter.data} dataTableSetting={settings} fetchData={fetchData} totalRows={transporter?.total} />
            {isEditTransporterOpen === 'edit' && (
                <TransporterForm
                    handleExternalFetch={fetchData}
                    isOpen={isEditTransporterOpen==='edit'}
                    onRequestClose={() => setIsEditTransporterOpen(false)}
                    selectedData={selectedTransporter}
                    isEditMode={true}
                />
            )}
        </>
    );
};

export default TransporterTable;
