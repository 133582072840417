import React, {useCallback, useMemo, useState, useRef, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callUpdateDataWithLogFunction, callSoftDeleteDataWithLogFunction } from '../../../store/action/commonActions';
import Badge from "../../Layout/Badge";
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBox,
    faBoxesPacking,
    faFileCirclePlus,
    faHandshake,
    faHandshakeSlash
} from "@fortawesome/free-solid-svg-icons";
import useAuth from '../../../hooks/useAuth';
import SchoolClientLeadForm from "./SchoolClientLeadForm";
import SchoolClientLeadInteractionForm from "../client-lead-interaction/SchoolClientLeadInteractionForm";
import SchoolClientLeadInteractionTableModal from "../client-lead-interaction/SchoolClientLeadInteractionTableModal";
import {useFetchData} from "../../../hooks/useFetchData";
import LeadToOrderFormModal from "../lead-to-order/LeadToOrderFormModal";
import LeadToOrderTableModal from "../lead-to-order/LeadToOrderTableModal";
import GemSchoolForm from "../school/GemSchoolForm";
import GemTableModal from "../lead-to-order/GemTableModal";

const SchoolClientLeadTable = ({ setFetchDataTable, fetchDataTable, selectedSchool }) => {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const { data: schoolData, fetchData, settings } = useFetchData('sales/get-school-client-lead', [], { school_id: selectedSchool?.id });

    const [isEditSchoolClientLeadOpen, setIsEditSchoolClientLeadOpen] = useState(null);
    const [selectedSchoolClientLead, setSelectedSchoolClientLead] = useState(null);
    const [openBadge, setOpenBadge] = useState(null);
    const tableContainerRef = useRef(null);


    // Dropdown options memoized
    const dropdownOptions = useMemo(() => ({
        quotation: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' }
        ],
        interestLevel: [
            { value: 'High', label: 'High' },
            { value: 'Medium', label: 'Medium' },
            { value: 'Low', label: 'Low' }
        ],
        leadStatus: [
            { value: 'New', label: 'New' },
            { value: 'Contacted', label: 'Contacted' },
            { value: 'Demo Scheduled', label: 'Demo Scheduled' },
            { value: 'Closed Won', label: 'Closed Won' },
            { value: 'Closed Lost', label: 'Closed Lost' }
        ]
    }), []);

    const handleDropdownToggle = useCallback((uniqueKey) => {
        setOpenBadge(prevKey => prevKey === uniqueKey ? null : uniqueKey);
    }, []);

    const handleBadgeUpdate = useCallback((newStatus, key, id) => {
        const columnName = key.split('-')[0];
        const payload = { [columnName]: newStatus };
            dispatch(callUpdateDataWithLogFunction({
                tableName: 'school_client_lead',
                data: payload,
                id,
                userId: auth?.id,
                eventTypeId
            })).then(() => {
                setOpenBadge(null);
                fetchData();
            });
    }, [auth?.id, dispatch, eventTypeId, fetchData]);

    const getBadgeHtml = (key, label, color, options, id) => (
            <Badge
                key={key}
                label={label}
                color={color}
                options={options}
                isOpen={openBadge === key}
                onDropdownToggle={() => handleDropdownToggle(key)}
                uniqueKey={key}
                id={id}
                onUpdate={(newStatus) => handleBadgeUpdate(newStatus, key, id)}
                tableContainerRef={tableContainerRef}
            />
    );

    // Memoize the columns for performance
    const columns = useMemo(() => {
        const baseColumns = [
            { name: 'Sr No', selector: (row, index) => index + 1, width: '60px' },
            { name: 'Name', selector: row => row.name, sortable: true, width: '150px' },
            { name: 'Order Type', selector: row => row.order_type_name, sortable: true, width: '100px' },
            { name: 'Order Value', selector: row => row.order_value, sortable: true, width: '200px' },
            { name: 'Creator', selector: row => row.creator, sortable: true, width: '200px' },
            {
                name: 'Admin Attention Required',
                selector: row => row.admin_attention_required,
                cell: (row, index) => getBadgeHtml(`admin_attention_required-${index}`, row.admin_attention_required === '1' ? 'Yes' : 'No',
                    row.admin_attention_required === '0' ? 'green' : 'red', dropdownOptions.quotation, row.id),
                width: '100px'
            },
            {
                name: 'Gem Order',
                selector: row => row.gem_order,
                cell: (row, index) => getBadgeHtml(`gem_order-${index}`, row.gem_order === '1' ? 'Yes' : 'No',
                    row.gem_order === '0' ? 'red' : 'green', dropdownOptions.quotation, row.id),
                width: '100px'
            },
            {
                name: 'Quotation Status',
                selector: row => row.quotation_status,
                cell: (row, index) => getBadgeHtml(`quotation_status-${index}`, row.quotation_status === '1' ? 'Yes' : 'No',
                    row.quotation_status === '1' ? 'green' : 'red', dropdownOptions.quotation, row.id),
                width: '100px'
            },
            {
                name: 'Interest Level',
                selector: row => row.interest_level,
                cell: (row, index) => getBadgeHtml(`interest_level-${index}`, row.interest_level,
                    row.interest_level === 'High' ? 'green' : row.interest_level === 'Medium' ? 'orange' : 'red',
                    dropdownOptions.interestLevel, row.id),
                width: '100px'
            },
            {
                name: 'Lead Status',
                selector: row => row.lead_status,
                cell: (row, index) => getBadgeHtml(`lead_status-${index}`, row.lead_status,
                    row.lead_status === 'New' ? 'orange' : row.lead_status === 'Closed Won' ? 'green' : 'red',
                    dropdownOptions.leadStatus, row.id),
                width: '100px'
            },
            {
                name: 'Actions', cell: (row) => (
                    <div className='action-components'>
                        <TableEditActions
                            tooltip={"Edit Business Regarding"}
                            row={row}
                            onEdit={() => { setSelectedSchoolClientLead(row); setIsEditSchoolClientLeadOpen('edit'); }}
                        />
                        <TableDeleteActions
                            tooltip={"Delete Business Regarding"}
                            row={row}
                            onDelete={() => dispatch(callSoftDeleteDataWithLogFunction({
                                tableName: 'school_client_lead',
                                id: row.id,
                                userId: auth?.id,
                                eventTypeId
                            })).then(fetchData)}
                        />
                        <Tippy content="Interaction Add">
                            <button className='action-button' onClick={() => { setSelectedSchoolClientLead(row); setIsEditSchoolClientLeadOpen('add_lead_interaction'); }}>
                                <FontAwesomeIcon className='action-icon' icon={faHandshakeSlash} />
                            </button>
                        </Tippy>
                        <Tippy content="Interaction Table">
                            <button className='action-button' onClick={() => { setSelectedSchoolClientLead(row); setIsEditSchoolClientLeadOpen('lead_interaction_table'); }}>
                                <FontAwesomeIcon className='action-icon' icon={faHandshake} />
                            </button>
                        </Tippy>

                        {row.gem_order === '1' &&<><Tippy content="Add Gem Bid">
                            <button className='action-button' onClick={() => { setSelectedSchoolClientLead(row); setIsEditSchoolClientLeadOpen('add_gem_details');}}>
                                <FontAwesomeIcon className='action-icon' icon={faFileCirclePlus} />
                            </button>
                        </Tippy><Tippy content="Gem Bids">
                            <button className='action-button' onClick={() => { setSelectedSchoolClientLead(row); setIsEditSchoolClientLeadOpen('gem_details');}}>
                                <FontAwesomeIcon className='action-icon' icon={faFileCirclePlus} />
                            </button>
                        </Tippy></>}
                        {row.lead_status === 'Closed Won' &&
                            <>
                                <Tippy content="Create Order">
                                    <button className='action-button' onClick={() => { setSelectedSchoolClientLead(row);  setIsEditSchoolClientLeadOpen('lead_to_order_form'); }}>
                                        <FontAwesomeIcon className='action-icon' icon={faBox} />
                                    </button>
                                </Tippy>
                                <Tippy content="Order Table">
                                    <button className='action-button' onClick={() => { setSelectedSchoolClientLead(row);  setIsEditSchoolClientLeadOpen('lead_to_order_table'); }}>
                                        <FontAwesomeIcon className='action-icon' icon={faBoxesPacking} />
                                    </button>
                                </Tippy>
                            </>
                        }
                    </div>
                ), width: '150px'
            }
        ];

        if (!selectedSchool) {
            baseColumns.splice(1, 0, { name: 'Business Type', selector: row => row.business_type_name, sortable: true, width: '100px' });
            baseColumns.splice(1, 0, { name: 'School', selector: row => row.school_name, sortable: true, width: '200px' });
        }

        return baseColumns;
    }, [selectedSchool, dropdownOptions, handleBadgeUpdate,schoolData]);

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable, fetchData]);
    console.log(selectedSchoolClientLead,'selectedSchoolClientLead');

    return (
        <>
            <DataTableLayout keyField="id" columns={columns} data={schoolData.data} dataTableSetting={settings} fetchData={fetchData} totalRows={schoolData?.total} tableContainerRef={tableContainerRef} />
            {isEditSchoolClientLeadOpen === 'edit' && (
                <SchoolClientLeadForm
                    handleExternalFetch={fetchData}
                    isOpen
                    onRequestClose={() => setIsEditSchoolClientLeadOpen(null)}
                    selectedData={selectedSchoolClientLead}
                    isEditMode
                />
            )}
            {isEditSchoolClientLeadOpen === 'add_lead_interaction' && (
                <SchoolClientLeadInteractionForm
                    isOpen
                    onRequestClose={() => setIsEditSchoolClientLeadOpen(null)}
                    selectedData={selectedSchoolClientLead}
                />
            )}
            {isEditSchoolClientLeadOpen === 'lead_interaction_table' && (
                <SchoolClientLeadInteractionTableModal
                    isOpen
                    onRequestClose={() => setIsEditSchoolClientLeadOpen(null)}
                    selectedLead={selectedSchoolClientLead}
                />
            )}
            {isEditSchoolClientLeadOpen === 'lead_to_order_table' && (
                <LeadToOrderTableModal
                    isOpen
                    onRequestClose={() => setIsEditSchoolClientLeadOpen(null)}
                    selectedClientLead={selectedSchoolClientLead}
                />
            )}
            {isEditSchoolClientLeadOpen === 'gem_details' && (
                <GemTableModal
                    isOpen
                    onRequestClose={() => setIsEditSchoolClientLeadOpen(null)}
                    selectedClientLead={selectedSchoolClientLead}
                />
            )}
            {isEditSchoolClientLeadOpen === 'lead_to_order_form' && (
                <LeadToOrderFormModal
                    isOpen
                    onRequestClose={() => setIsEditSchoolClientLeadOpen(null)}
                    selectedData={{client_lead_id:selectedSchoolClientLead.id,school_id:selectedSchoolClientLead.school_id}}
                />
            )}

            {isEditSchoolClientLeadOpen === 'add_gem_details' && (
                <GemSchoolForm
                    isOpen={isEditSchoolClientLeadOpen=== 'add_gem_details'}
                    onRequestClose={() => setIsEditSchoolClientLeadOpen(false)}
                    selectedData={{client_lead_id:selectedSchoolClientLead.id,school_id:selectedSchoolClientLead.school_id}}
                    isEditMode={false}
                />
            )}
        </>
    );
};

export default SchoolClientLeadTable;
