import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Signature from "../../../../images/LT_Sign-removebg-preview.png";

const Invoice = () => {
    const pdfRef = useRef();

    const generatePDF = () => {
        const doc = new jsPDF('p', 'pt', 'a4');
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = doc.internal.pageSize.getHeight();

        html2canvas(pdfRef.current, {
            useCORS: true,
            scale: 3,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const aspectRatio = imgHeight / imgWidth;

            const pdfCanvasHeight = pdfWidth * aspectRatio;

            if (pdfCanvasHeight > pdfHeight) {
                doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            } else {
                doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfCanvasHeight);
            }

            doc.save('tax_invoice.pdf');
        });
    };

    return (

        <div style={{ fontFamily: 'Arial, sans-serif', width: '80%', margin: '15px auto', padding: '20px', border: '1px solid #ddd', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <div ref={pdfRef} id="invoice-content" style={{ padding: '20px' }}>
                <img
                    src="https://shikshak-solutions.s3.eu-north-1.amazonaws.com/logo/dummy.png?v=8995"
                    alt="Logo"
                    crossOrigin="anonymous"
                    style={{ float: 'left', width: '240px', marginRight: '20px', marginTop: '10px' }}
                />
                <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                    <h3>Tax Invoice/Bill of Supply/Cash Memo</h3>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', fontSize: '20px' }}>
                    {/*<p><strong>Original for Recipient</strong></p>*/}
                </div>
                <section>
                    <table style={{ width: '100%', marginTop: '10px' }}>
                        <tbody>
                        <tr style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <td>
                                <h4><strong>Sold By:</strong></h4>
                                <h5><strong>Learnocean Technology Pvt. Ltd.</strong></h5>
                                <p>B8-505, Kherki Daula Road, Sector 76</p>
                                <p>Gurugram Dist Gurugram, Haryana, 122004 IN</p>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <h4><strong>Billing Address :</strong></h4>
                                <h5><strong>Saurabh Abhishek</strong></h5>
                                <p>861 Ground Floor, Sector 47</p>
                                <p>Gurgaon, Haryana, 122018 IN</p>
                                <p><strong>State/UT Code : </strong>06</p>
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <td>
                                <h5><strong>PAN No:</strong> AAFCL8630B</h5>
                                <h5><strong>GST Registration No:</strong> 06AAFCL8630B1ZS</h5>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <h4><strong>Shipping Address :</strong></h4>
                                <h5><strong>Saurabh Abhishek</strong></h5>
                                <p>861 Ground Floor, Sector 47</p>
                                <p>Gurgaon, Haryana, 122018 IN</p>
                                <p><strong>State/UT Code : </strong>06</p>
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <td>
                                <h5><strong>Order Number :</strong> 407-8427449-1015518</h5>
                                <h5><strong>Order Date : </strong>04.09.2023</h5>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <h5><strong>Place of Supply:</strong> HARYANA</h5>
                                <h5><strong>Place of Delivery:</strong> HARYANA</h5>
                                <h5><strong>Invoice Number:</strong> FDLB-228682</h5>
                                <h5><strong>Invoice Details:</strong> HR-FDLB-1293787125-2324</h5>
                                <h5><strong>Invoice Date:</strong> 04.09.2023</h5>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                <div>
                    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                        <thead>
                        <tr>
                            <th style={{ backgroundColor: "#f0f0f0", border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                                Sl. No
                            </th>
                            <th style={{ backgroundColor: "#f0f0f0", border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                                Description
                            </th>
                            <th style={{ backgroundColor: "#f0f0f0", border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                             Unit Price
                            </th>
                            <th style={{ backgroundColor: "#f0f0f0", border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                                Qty
                            </th>
                            <th style={{ backgroundColor: "#f0f0f0", border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                                Net Amount
                            </th>
                            <th style={{ backgroundColor: "#f0f0f0", border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                                Tax Rate
                            </th>
                            <th style={{ backgroundColor: "#f0f0f0", border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                                Tax Type
                            </th>
                            <th style={{ backgroundColor: "#f0f0f0", border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                                Tax Amount
                            </th>
                            <th style={{ backgroundColor: "#f0f0f0", border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                                Total Amount
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>1</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>
                                Robotics Starter Kit
                            </td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>₹233.90</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>1</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>₹233.90</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>9%</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>CGST</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>₹21.05</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>₹276.00</td>
                        </tr>
                        <tr>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}></td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}></td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}></td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}></td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}></td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>9%</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>SGST</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}>₹21.05</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}></td>
                        </tr>
                        <tr>
                            <td style={{border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }} colSpan="7"><strong>Grand Total:</strong></td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }} className="grandTotal">₹ 42.10</td>
                            <td style={{ border: "3px solid #000", padding: "8px", textAlign: "left", fontSize: "18px" }}> ₹ 276.00</td>
                        </tr>
                        </tbody>
                    </table>

                    <div style={{ marginTop: "20px", border: "3px solid #000", textAlign: "left", fontSize: "20px", paddingLeft: "10px" }}>
                        <h5 style={{ marginTop: "10px"}}>Total Amount in Words :</h5>
                        <p><strong>Two Hundred Seventy-six Only</strong></p>
                    </div>

                    <div style={{ textAlign: "right", paddingRight: "20px", marginTop: "10px" }}>
                        <h5>For Learnocean Technology Pvt. Ltd :</h5>
                        <img src={Signature} alt='Sign' className='w-48'/>
                        <h6>Authorized Signatory</h6>
                    </div>

                    <h6 style={{ marginTop: "20px" }}>Whether Tax is Payable under Reverse Charge - No</h6>

                    <div style={{ marginTop: "20px",  display: "flex", justifyContent: "space-between", border: "1px solid #000", fontSize: "16px", paddingLeft: "10px", paddingRight: "5px" }}>
                    <p><strong>Payment Transaction ID :</strong> <br/> 2ltMAFf4QZ2MZoJBrUDK</p>
                    <p><strong>Date & Time :</strong> <br/>04/09/2024, 08:44:25 hrs</p>
                    <p><strong>Invoice Value :</strong> <br/> ₹276.00</p>
                    <p><strong>Mode of Payment :</strong> <br/> Credit Card</p>
                    </div>
                </div>
                </section>
        </div>
    <button onClick={generatePDF} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '18px', backgroundColor: '#0073e6', color: '#fff', border: 'none', cursor: 'pointer' }}>
        Download Invoice as PDF
    </button>
</div>
);
};

export default Invoice;
